@import url(./styles/font.scss);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif !important;
}

h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 0 !important;
}