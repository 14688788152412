.ant-modal-content {
  border-radius: 16px !important;
}

.ant-notification {
  top: 20px !important;
}

.ant-notification-notice-icon {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.ant-notification-notice-close {
  top: 8px !important;
  right: 10px !important;
}

.ant-alert-icon .anticon, .ant-notification-notice-close-x .anticon {
  vertical-align: 0 !important;
}
.ant-input {
  width: 100% !important;
  border-radius: 10px !important;
  //height: 40px !important;
}
.ant-select .ant-select-selector{
  border-radius: 10px !important;
  height: 40px !important;
}
.ant-checkbox {

  color: #19D3C5 !important;

}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border: 2px solid #E31D93 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  //transform: rotate(45deg) scale(1) translate(-50%,-50%);
  //opacity: 1;
  border-color: #19D3C5 !important;
}
.ant-form-item-control {
  width: 100%;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 5px !important;
}
